.App {
  width:100%;
  height:auto;
  display:flex;
  flex-direction:column;
  font-family: Arial, Helvetica, san-serif;
  background-color: #f0f0f0;
}


body{
  padding:0;
  margin:0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
